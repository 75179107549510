import { useTranslation } from 'react-i18next';
import { StatusIcon } from 'shared-frontend';
import { useGetAppState } from '../../../store/features/appStateSlice/appStateSlice';
import StockCorrections from './StockCorrections';
import { Status } from './itemConditionOfGoods.types';
import { getArticleItemType } from '../ArticlesReasonCardUtils';
import { ArticleListItemProps } from '../types';
import { ReceivedArticleItem, ReceivedItem } from '../../../models/CustomerReturn';
import TooltipWithCondition from '../../Tooltips/TooltipWithCondition';

interface ReceivingInfoProps {
  article: ArticleListItemProps;
}

export default function ReceivingInfo({ article }: ReceivingInfoProps) {
  const { t } = useTranslation();
  const showStockCorrection = useGetAppState('showStockCorrection');

  const type = getArticleItemType(article);
  if (!(type === 'ITEM_WITH_RECEIVING_INFO' || type === 'UNEXPECTED_ITEM')) {
    return null;
  }

  const {
    id,
    receiving: receivingInfo,
  } = article as (ReceivedArticleItem | ReceivedItem);
  const articleQuantity = type === 'ITEM_WITH_RECEIVING_INFO'
    ? (article as ReceivedArticleItem).quantity
    : (article as ReceivedItem).expectedQuantity;

  const { okQuantity, damagedQuantity } = receivingInfo;

  const someItemsReturned = okQuantity + damagedQuantity < articleQuantity
    && okQuantity + damagedQuantity > 0;
  const allItemsReturned = okQuantity + damagedQuantity === articleQuantity;
  const noItemsReturned = okQuantity + damagedQuantity === 0;
  const moreItemsThanExpected = okQuantity + damagedQuantity > articleQuantity;

  const statuses: Status[] = [
    {
      icon: type === 'UNEXPECTED_ITEM' ? 'error' : 'check',
      label: `${okQuantity + damagedQuantity} ${t('articles-reason-card.receivingInfo.returned')}`,
      show: allItemsReturned,
      testId: 'checkmark',
    },
    {
      icon: 'error',
      label: someItemsReturned || moreItemsThanExpected
        ? `${okQuantity + damagedQuantity} ${t('articles-reason-card.receivingInfo.returned')}`
        : t('articles-reason-card.receivingInfo.noItemsReceived'),
      show: noItemsReturned || someItemsReturned || moreItemsThanExpected,
      testId: 'errorIcon',
    },
    {
      icon: 'info',
      label: `${damagedQuantity} ${t('articles-reason-card.receivingInfo.receivedInfo')}`,
      show: allItemsReturned || someItemsReturned || moreItemsThanExpected,
      testId: 'infoIcon',
      tooltipText: t('articles-reason-card.receivingInfo.receivedInfoTooltip'),
    },
  ];

  const firstItemTopPadding = !!article.conditionOfGoods;

  return (
    <div data-testid="receivingInfo">
      {statuses.map(({
        icon, label, show, testId, classes = '', tooltipText = null,
      }, i) => (
        show && (
          <TooltipWithCondition key={`${id}-${icon}`} tooltipText={tooltipText}>
            <StatusIcon
              className={`${!firstItemTopPadding && i === 0 ? '' : 'pt-2'} ${classes}`}
              icon={icon}
              label={label}
              testId={testId}
            />
          </TooltipWithCondition>
        )))}
      {showStockCorrection && <StockCorrections receivingInfo={receivingInfo} />}
    </div>
  );
}
