import { Card } from 'shared-frontend';
import { useTranslation } from 'react-i18next';
import Statusbar from './Statusbar';
import StatusbarDraft from './StatusbarDraft';
import { useCreateRoute } from '../../hooks/useAppRoutes';
import { useGetAppState } from '../../store/features/appStateSlice/appStateSlice';

function StatusbarCard() {
  const isCreateRoute = useCreateRoute();
  const returnReference = useGetAppState('returnReference');
  const showDraftStatusbar = isCreateRoute && !returnReference;
  const StatusbarComponent = showDraftStatusbar ? StatusbarDraft : Statusbar;
  const { t } = useTranslation();
  const skipContentLabel = t('statusViewModel.steps.statusbar-carousel.skipStatusbar');

  return (
    <Card>
      <StatusbarComponent skipLabel={skipContentLabel} />
    </Card>
  );
}

export default StatusbarCard;
